import React, { useEffect } from "react";
import { useState } from "react";
import request from "../services/request"
import { navigate } from 'gatsby';

// import { login } from "../redux/actions/auth";

const Login = () => {
  // const navigate=useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
//   const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    if(!email || !password) {
      event.preventDefault()
      alert("Please enter all the fields")
    }
    // dispatch(login({email, password}, event))
    const response = await request.post('/token/', { email,password});

    localStorage.setItem('token', response.data.access);
    localStorage.setItem('user', JSON.stringify(response.data));
    navigate(0)
}

  return (
    <>
      <div className="outer">
        <h1>Login</h1>
        <form>
          <div className="row">
            <label for="email">Email</label>
            <input type="email" name="email" placeholder="email@example.com" autoFocus='true' value={email} onChange={e => setEmail(e.target.value)}/>
          </div>
          <div className="row">
            <label for="password">Password</label>
            <input type="password" name="password" value={password} onChange={e => setPassword(e.target.value)}/>
          </div>
          <button type="button" className="signin" onClick={e => handleSubmit(e)}>Login</button>
        </form>
      </div>
    </>
  );
};

export default Login;